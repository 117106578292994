<template>
  <div class="main">
    <div class="main-container">
      <div class="side-bar">
        <img class="myphoto" src="../assets/me.jpg" />
        <h2>Shubham Rangra</h2>
        <button class="special">Mobile Developer</button>
        <hr />
        <div v-if="activeCard == 'resumeCard'" class="skills">
          <h4>Skills</h4>
          <div class="skills">
            <button>C#</button>
            <button>SQL</button>
            <button>HTML/Css</button>
            <button>Kotlin</button>
            <button>Jetpack Compose</button>
            <button>Vue</button>
            <button>Python</button>
          </div>
        </div>
        <div v-if="activeCard == 'aboutCard'" class="contact">
          <div class="infoCard">
            <img src="../assets/mailIcon.png" />
            <div class="detail">
              <h5>eMail</h5>
              <p>shubhamrangra80@gmail.com</p>
            </div>
          </div>
          <div class="infoCard">
            <img src="../assets/phoneIcon.png" />
            <div class="detail">
              <h5>Phone</h5>
              <p>02102716273</p>
            </div>
          </div>
          <div class="infoCard">
            <img src="../assets/linkedinIcon.png" />
            <div class="detail">
              <h5>LinkedIn</h5>
              <a
                href="https://www.linkedin.com/in/shubham-rangra-558146239/"
                target="_blank"
                >Shubham.R</a
              >
            </div>
          </div>
          <div class="infoCard">
            <img src="../assets/githubIcon.png" />
            <div class="detail">
              <h5>Github</h5>
              <a href="https://www.github.com/shubr" target="_blank">Shubr</a>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <NavigationBar @switchCard="switchCard"></NavigationBar>
        <div class="content">
          <AboutMe v-if="activeCard == 'aboutCard'"></AboutMe>
          <ResumePage v-if="activeCard == 'resumeCard'"></ResumePage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import AboutMe from "./AboutMe.vue";
import ResumePage from "./ResumePage.vue";
export default {
  name: "MainPage",
  components: { NavigationBar, AboutMe, ResumePage },
  data() {
    return {
      activeCard: "aboutCard",
    };
  },
  methods: {
    switchCard(cardName) {
      this.activeCard = cardName;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
}
.main {
  width: 100vw;
  height: 100vh;
  background-color: #131313;
}

.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-decoration: none;
}

.side-bar {
  width: 300px;
  height: 92%;
  background-color: #262626;
  border-radius: 20px;
  border: 1px solid #4e4e4e;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  gap: 30px;
}

.infoCard {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.infoCard .detail {
  display: flex;
  flex-direction: column;
}

.infoCard .detail a {
  color: white;
  text-decoration: none;
}

.infoCard h5 {
  color: #8e8e8e;
}
.infoCard p {
  font-size: 15px;
}

.infoCard img {
  width: 30px;
  height: 30px;
}

.skills h4 {
  text-align: center;
  padding-bottom: 5px;
}

.myphoto {
  width: 240px;
  height: 240px;
  background-color: #444444;
  border-radius: 100%;
  margin-top: 25px;
  object-fit: cover;
}

.side-bar button {
  background-color: #3a3a3a;
  border-style: none;
  color: white;
  font-size: 13px;
  border: 1px solid #6b6b6b;
  border-radius: 10px;
  padding: 8px 12px;
  margin: 5px;
  font-weight: bold;
}
hr {
  width: 250px;
}
.skills {
  width: 90%;
}

.main-content {
  width: 70%;
  height: 92%;
  background-color: #262626;
  border-radius: 20px;
  border: 1px solid #4e4e4e;
}

.content {
  width: 90%;
  height: 85%;
  margin: 15px 10px;
  overflow-y: auto;
}
</style>
