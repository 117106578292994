<template>
  <div class="about-me-container">
    <h1>About Me</h1>
    <br /><br />
    <p>
      Highly motivated and passionate IT student with a keen interest in
      Software and Mobile development. Seeking to<br />
      leverage my technical knowledge and programming skills gained during
      studies at UCOL to <br />
      contribute to an innovative organisation and further enhance my abilities
      <br />
      in software development.
    </p>
    <br /><br /><br /><br />

    <h1>What I'm doing</h1>
    <br />

    <div class="working-container">
      <div class="work">
        <img src="../assets/laptopIcon.png" />
        <div class="info">
          <h2>Mobile Dev</h2>
          <p>
            Creating high-quality app for both androidand ios, optimised to run
            smoothly on any device.
          </p>
        </div>
      </div>

      <div class="work">
        <img src="../assets/laptopIcon.png" />
        <div class="info">
          <h2>Web Dev</h2>
          <p>Currently learning Vue framework, to make responsive webpage.</p>
        </div>
      </div>

      <div class="work">
        <img src="../assets/pencilIcon.png" />
        <div class="info">
          <h2>Design</h2>
          <p>
            Using Figma to create eye catching design weather its a web page or
            a mobile app.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
* {
  color: white;
}

.about-me-container {
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 35px;
}
p {
  color: #b7b7b7;
}
.working-container {
  width: 100%;
  height: 60%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  overflow-y: auto;
}

@media screen and (min-width: 1300px) {
  .working-container {
    grid-template-columns: 1fr 1fr;
  }
}

.working-container .work {
  width: 440px;
  height: 150px;
  background-color: #191919;
  border-radius: 20px;
  border: 1px solid #6b6b6b;
  display: flex;
  flex-direction: row;
}

.work img {
  width: 150px;
  height: 150px;
}
.work .info {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  gap: 10px;
}
</style>
