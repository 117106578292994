<template>
  <div class="resume-container">
    <h1>Resume</h1>
  </div>
</template>

<script></script>

<style scoped>
.resume-container {
  width: 100%;
  height: 100%;
}
h1 {
  color: white;
}
</style>
