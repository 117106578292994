<template>
  <MainPage></MainPage>
</template>

<script>
import MainPage from "./components/MainPage.vue";

export default {
  name: "App",
  components: {
    MainPage,
  },
};
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
}
</style>
