<template>
  <div class="nav-container">
    <div class="nav-bar">
      <li>
        <a
          href="#"
          :class="{ selected: activeCard == 'aboutCard' }"
          @click="switchTo('aboutCard')"
          >About</a
        >
        <a
          href="#"
          :class="{ selected: activeCard == 'resumeCard' }"
          @click="switchTo('resumeCard')"
          >Resume</a
        >
        <a href="#" @click="switchTo()" class="">Protafolio</a>
        <a href="#" @click="switchTo()" class="">Contact</a>
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  data() {
    return {
      activeCard: "aboutCard",
    };
  },
  methods: {
    switchTo(cardName) {
      this.activeCard = cardName;
      this.$emit("switchCard", cardName);
    },
  },
};
</script>

<style scoped>
.nav-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: end;
}
.nav-bar {
  width: 404px;
  height: 80px;
  border-radius: 0px 20px 0px 20px;
  background-color: #3a3a3a;
  border: 1px solid #4e4e4e;
}
li {
  display: flex;
  text-decoration: none;
  list-style: none;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: bold;
  gap: 15px;
  height: 100%;
}
a {
  text-decoration: none;
  color: white;
}

.selected {
  color: #ffbb00;
}
</style>
